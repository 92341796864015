<template>
  <div>
    <v-container>
      <v-row justify="space-between">
        <v-col cols="3" class="pl-0 py-0">
          <v-text-field
            dense
            v-model="currentItem.reg_number"
            label="Registratsiya raqami"
            outlined
            @blur="aa('reg_number', currentItem.reg_number)"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="pl-0 py-0">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="currentItem.oper_date"
                @change="aa('oper_date', currentItem.oper_date)"
                label="Sana"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @change="aa('oper_date', currentItem.oper_date)"
              v-model="currentItem.oper_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" class="pl-0 py-0">
          <v-select
            dense
            v-model="currentItem.payment_source"
            @blur="aa('payment_source', currentItem.payment_source)"
            :items="paymentSources"
            item-text="name"
            item-value="id"
            outlined
            label="Manba"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pl-0 py-0">
          <v-combobox
            :items="allStuffs"
            v-model="currentItem.responsible"
            v-debounce:400ms="myStaff"
            @blur="aa('responsible', currentItem.responsible.id)"
            item-text="full_name"
            item-value="id"
            label="Masul shaxs"
            outlined
          ></v-combobox>
        </v-col>
        <v-col cols="6" class="pl-0 py-0">
          <v-combobox
            dense
            :items="contragentsList"
            v-model="currentItem.full_name"
            @change="getContracts"
            @blur="aa('contragent', currentItem.full_name.id)"
            v-debounce:400ms="myFn"
            item-text="full_name"
            item-value="id"
            label="Kontagent"
            outlined
          ></v-combobox>
        </v-col>
        <v-col cols="6" class="pl-0 py-0 pb-2">
          <v-combobox
            dense
            :items="contragent_contractLists"
            v-model="currentItem.contragent_contract"
            @blur="
              aa('contragent_contract', currentItem.contragent_contract.id)
            "
            item-text="reg_number"
            item-value="id"
            label="Kontagent Shartnomasi"
            outlined
          ></v-combobox>
        </v-col>
        <v-col cols="12" class="pl-0 py-0">
          <v-textarea
            v-model="currentItem.comment"
            @blur="aa('comment', currentItem.comment)"
            name="input-7-1"
            label="Comment"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import debounce from "debounce";
// import { required } from "vuelidate/lib/validators";
export default {
  //   directives: { currency: CurrencyDirective },
  props: ['item', 'parentId', 'saveStatus'],
  data() {
    return {
      menu2: false,
      allData: {},
      currentItem: {},
      contragentsList: [],
      contragent_contractLists: []
    }
  },
  created() {
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('getPaymentSource')
    let data = {
      responsible: this.item.responsible_name,
      reg_number: this.item.reg_number,
      oper_date: this.item.oper_date,
      full_name: this.item.contragent_name,
      contragent_contract: this.item.contragent_contract_number,
      payment_source: this.item.payment_source,
      comment: this.item.comment
    }
    this.currentItem = data
  },
  watch: {
    saveStatus: function (val) {
      if (val == false) {
        this.$emit('toggle', false)
        this.save()
      }
    },
    item: function (val) {
      let data = {
        responsible: val.responsible_name,
        reg_number: val.reg_number,
        oper_date: val.oper_date,
        full_name: val.contragent_name,
        contragent_contract: val.contragent_contract_number,
        payment_source: val.payment_source,
        comment: val.comment
      }
      this.currentItem = data
    }
  },
  computed: {
    paymentSources() {
      return this.$store.state.requests.paymentSource
    },
    allStuffs() {
      return this.$store.state.requests.allStaff
    }
  },
  methods: {
    getContracts() {
      if (this.contragent !== null) {
        this.$store
          .dispatch('counteragentContracts', this.currentItem.full_name.id)
          .then((res) => {
            this.contragent_contractLists = []
            this.contragent_contractLists = [...res]
          })
      }
    },
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('counteragentsSearch', data).then((res) => {
        this.contragentsList = []
        this.contragentsList = [...res]
      })
    },
    myStaff(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('getStaffList', data)
    },
    aa(objKey, value) {
      this.allData[objKey] = value
    },
    save() {
      this.$store.dispatch('mainAssetsMainDocPart', {
        id: this.item.id,
        data: this.allData,
        parentId: this.$route.params.id
      })
      this.allData = {}
    }
  }
}
</script>
