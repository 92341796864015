<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Asosiy vositalar malumotlari</h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <h3>Asosiy vositalar o'zgartirish</h3>
              <div class="d-flex flex-wrap">
                <!-- {{getDetailsMain}} -->
                <div class="col-3">
                  <!-- <label>Nomi</label> -->
                  <v-text-field
                    @blur="update('name', allData.name)"
                    dense
                    outlined
                    label="Nomi"
                    v-model="getDetailsMain.name"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label>Kirim Sanasi</label> -->
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        @blur="update('import_date', allData.import_date)"
                        dense
                        v-model="allData.import_date"
                        label="Kirim Sanasi"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="allData.import_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                  <!-- <v-text-field dense outlined v-model="allData.import_date"> -->
                  <!-- </v-text-field> -->
                </div>
                <div class="col-3">
                  <v-text-field
                    @blur="update('reg_number', allData.reg_number)"
                    label="Reg raqam"
                    dense
                    outlined
                    v-model="allData.reg_number"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label></label> -->

                  <v-text-field
                    outlined
                    label="Inventar #"
                    @blur="update('inventar_number', allData.inventar_number)"
                    dense
                    v-model="allData.inventar_number"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label></label> -->
                  <v-text-field
                    @blur="update('total_amount', allData.total_amount)"
                    v-currency="options"
                    label="Summa"
                    outlined
                    dense
                    v-model="allData.total_amount"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label>Muddati,Oy</label> -->
                  <v-text-field
                    label="Muddati,Oy"
                    @blur="update('period_usage', allData.period_usage)"
                    outlined
                    dense
                    v-model="allData.period_usage"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label></label> -->

                  <v-text-field
                    label="Hisobot davri boshidagi qoldiq"
                    v-currency="options"
                    @blur="
                      update(
                        'report_start_date_remain',
                        allData.report_start_date_remain
                      )
                    "
                    outlined
                    dense
                    v-model="allData.report_start_date_remain"
                  >
                  </v-text-field>
                </div>
                <div class="col-3">
                  <!-- <label>Sklad</label> -->
                  <v-autocomplete
                    dense
                    :items="allSklads"
                    @blur="
                      update('placement_warehouse', allData.placement_warehouse)
                    "
                    v-model="allData.placement_warehouse"
                    item-text="sklad_name"
                    item-value="id"
                    label="Sklad"
                    outlined
                  ></v-autocomplete>
                </div>
                <div class="col-12">
                  <!-- <label></label> -->

                  <v-textarea
                    @blur="update('comment', allData.comment)"
                    label="Izoh"
                    outlined
                    dense
                    v-model="allData.comment"
                  >
                  </v-textarea>
                </div>
              </div>
              <div class="mt-5">
                <v-data-table
                  :headers="headers"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  show-expand
                  loading-text="..."
                  no-data-text="Malumot kiritilmagan"
                  :items="getDoc"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>
                        <h3>Asosiy vositalar</h3>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="dialog2 = true"
                        dense
                        color="primary"
                        dark
                        class="mb-2"
                        >Dokument +</v-btn
                      >
                      <v-dialog v-model="dialog2" max-width="500px">
                        <v-card>
                          <v-card-title> Documentlar </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-autocomplete
                                    v-model="notTakenDoc"
                                    :items="notTakenDocs"
                                    item-text="contragent_name"
                                    item-value="id"
                                    label="Documentlar"
                                    outlined
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="dialog2 = false">Bekor qilish</v-btn>
                            <v-btn color="primary" @click="addDoc(notTakenDoc)"
                              >Saqlash</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.get_doc_total.total`]="{ item }">
                    {{ item.get_doc_total.total | mask }}
                  </template>
                  <template v-slot:[`item.get_doc_total.nds_summa`]="{ item }">
                    {{ item.get_doc_total.nds_summa | mask }}
                  </template>
                  <template
                    v-slot:[`item.get_doc_total.total_withnds_summa`]="{ item }"
                  >
                    {{ item.get_doc_total.total_withnds_summa | mask }}
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="elevation-0 p-0">
                      <v-data-table
                        :headers="headersdoc"
                        hide-default-footer
                        :items="item.details"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.import_group.name`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.import_group.name"
                            large
                            persistent
                            @save="
                              save('import_group', props.item.import_group)
                            "
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <template v-slot:input>
                              <v-select
                                class="mt-5"
                                @change="getCategorData(props.item)"
                                item-text="name"
                                item-value="id"
                                v-model="props.item.import_group"
                                :items="allCategoryGroupNames"
                                return-object
                                outlined
                                dense
                                label="Outlined"
                              ></v-select>
                              <v-select
                                class="mt-5"
                                item-text="name"
                                item-value="id"
                                v-model="props.item.import_category"
                                :items="getCategoryGroupData"
                                return-object
                                outlined
                                dense
                                label="Outlined"
                              ></v-select>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.amount`]="props">
                          <v-edit-dialog
                            :return-value.sync="
                              props.item.product_condition.name
                            "
                            height="200px"
                            large
                            persistent
                            @save="
                              save(
                                'product_condition',
                                props.item.product_condition
                              )
                            "
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.product_condition.name }}</div>
                            <template v-slot:input>
                              <div class="mt-4 title">Update Iron</div>
                            </template>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:[`input`]>
                              <v-select
                                class="mt-5"
                                item-text="name"
                                item-value="id"
                                v-model="props.item.product_condition"
                                :items="conditions"
                                return-object
                                dense
                                label="Outlined"
                              ></v-select>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:item.amount="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.amount"
                            height="200px"
                            large
                            persistent
                            @save="save('amount', props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.amount }}</div>
                            <template v-slot:input>
                              <div class="mt-4 title">Update Iron</div>
                            </template>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:[`input`]>
                              <v-text-field
                                class="mt-5"
                                v-model="props.item.amount"
                                dense
                                label="Outlined"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.price`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.price"
                            height="200px"
                            large
                            persistent
                            @save="save('price', props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.price | mask }}</div>
                            <template v-slot:input>
                              <div class="mt-4 title">Update Iron</div>
                            </template>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:[`input`]>
                              <v-text-field
                                class="mt-5"
                                v-model="props.item.price"
                                v-currency="options"
                                dense
                                label="Outlined"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.total`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.total"
                            height="200px"
                            large
                            persistent
                            @save="save('total', props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.total | mask }}</div>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:input>
                              <v-text-field
                                class="mt-5"
                                v-model="props.item.total"
                                v-currency="options"
                                dense
                                label="Outlined"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.nds`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.nds"
                            height="200px"
                            large
                            persistent
                            @save="save('nds', props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.nds | mask }}</div>
                            <template v-slot:input>
                              <div class="mt-4 title">Update Iron</div>
                            </template>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:[`input`]>
                              <v-text-field
                                class="mt-5"
                                v-model="props.item.nds"
                                dense
                                label="Outlined"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.nds_summa`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.nds_summa"
                            height="200px"
                            large
                            persistent
                            @save="save('nds_summa', props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            <div>{{ props.item.nds_summa | mask }}</div>
                            <template v-slot:input>
                              <div class="mt-4 title">Update Iron</div>
                            </template>
                            <!-- {{ props.item.product_condition.name }} -->
                            <template v-slot:[`input`]>
                              <v-text-field
                                class="mt-5"
                                v-model="props.item.nds_summa"
                                v-currency="options"
                                dense
                                label="Outlined"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.nds_per_item`]="props">
                          {{ props.item.nds_per_item | mask }}
                        </template>
                        <template v-slot:[`item.total_withnds_summa`]="props">
                          {{ props.item.total_withnds_summa | mask }}
                        </template>
                        <template v-slot:[`item.action`]="props">
                          <v-icon
                            small
                            color="warning"
                            class="mr-2"
                            @click="reset"
                          >
                            mdi-refresh
                          </v-icon>
                          <v-icon
                            small
                            color="success"
                            class="mr-2"
                            @click="updateDetail(props.item)"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          <v-icon
                            small
                            color="error"
                            class="mr-2"
                            @click="showModal(props.item.id)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                      small
                      color="warning"
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      color="error"
                      class="mr-2"
                      @click="showModal1(item)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        () => {
                          dialog3 = true
                          docsParentId = item.id
                        }
                      "
                      color="success"
                    >
                      mdi-plus-circle-outline
                    </v-icon>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog3" max-width="500px">
                  <v-card>
                    <v-card-title> Elementlar </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="mainAssetDocElement"
                              :items="mainAssetDocDetails"
                              item-text="total_withnds_summa"
                              item-value="id"
                              label="Elementlar"
                              outlined
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="dialog3 = false">Bekor qilish</v-btn>
                      <v-btn
                        color="primary"
                        @click="addDocElemet(mainAssetDocElement, docsParentId)"
                        >Saqlash</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <b-modal ref="my-modal1" hide-footer title="O’chirish">
                  <div class="d-block text-center">
                    <h3>O’chirish</h3>
                  </div>
                  <b-button
                    class="mt-3"
                    variant="outline-warning"
                    block
                    @click="hideModal1"
                    >Bekor qilish</b-button
                  >
                  <b-button
                    class="mt-2"
                    variant="outline-danger"
                    block
                    @click="deleteItem(deletedDocId)"
                  >
                    O’chirish
                  </b-button>
                </b-modal>
                <b-modal ref="my-modal" hide-footer title="O’chirish">
                  <div class="d-block text-center">
                    <h3>O’chirish</h3>
                  </div>
                  <b-button
                    class="mt-3"
                    variant="outline-warning"
                    block
                    @click="hideModal"
                    >Bekor qilish</b-button
                  >
                  <b-button
                    class="mt-2"
                    variant="outline-danger"
                    block
                    @click="deleteDetail(deletedDetailsId)"
                    >O’chirish</b-button
                  >
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Asosiy vositalar Hujjatlari</span>
            </v-card-title>
            <v-card-text>
              <main-asset-doc
                :item="currentItem"
                :saveStatus="saveStatus"
                :parentId="allData.id"
                @toggle="changeSaveStatus($event)"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn light @click="dialog = false">Bekor qilish</v-btn>
              <v-btn color="primary" @click="saveDoc">Saqlash</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import MainAssetDoc from './Components/MainAssetDocEditComponent'
export default {
  components: { MainAssetDoc },
  data() {
    return {
      deletedDetailsId: '',
      docsParentId: '',
      deletedDocId: '',
      mainAssetDocElement: '',
      dialog3: false,
      notTakenDoc: '',
      dialog2: false,
      allDetails: [],
      saveStatus: false,
      menu2: false,
      snack: false,
      snackColor: '',
      snackText: '',
      currentItem: {},
      dialog: false,
      allData: {},
      expanded: [],
      singleExpand: true,
      headersdoc: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'import_group.name',
          value: 'import_group.name'
        },
        {
          text: 'Nomenklatura nomi',
          value: 'import_category.name'
        },
        {
          text: 'Holati',
          value: 'product_condition.name'
        },
        {
          text: 'Soni',
          value: 'amount'
        },
        {
          text: 'Narxi',
          value: 'price'
        },
        {
          text: 'Jami',
          value: 'total'
        },
        {
          text: 'nds',
          value: 'nds'
        },
        {
          text: 'nds_per_item',
          value: 'nds_per_item'
        },
        {
          text: 'Nds summa',
          value: 'nds_summa'
        },
        {
          text: 'jami nds summa',
          value: 'total_withnds_summa'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Kontragent nomi',
          value: 'contragent_name'
        },

        {
          text: 'Kontragent Shartnoma #',
          value: 'contragent_contract_number'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Manba',
          value: 'payment_source_description'
        },
        {
          text: "Mas'ul",
          value: 'responsible_name'
        },
        {
          text: 'Summa',
          value: 'get_doc_total.total'
        },
        {
          text: 'NDS summa',
          value: 'get_doc_total.nds_summa'
        },
        {
          text: 'Summa(NDS)',
          value: 'get_doc_total.total_withnds_summa'
        },
        { text: '', value: 'data-table-expand' },
        { text: '', value: 'action', sortable: false }
      ],
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false
    }
  },
  created() {
    this.$store.dispatch('getmainassetsDetails', this.$route.params.id)
    this.$store.dispatch('getCategoryGroupNames')
    this.$store.dispatch('mainAssets', '1')
    this.$store.dispatch('getProductCondition')
    this.$store.dispatch('getAllSkladList')
    this.$store.dispatch('getMainAssetDetailsList')
  },
  watch: {
    getDetailsMain: function (val) {
      this.allData = val
      if (typeof this.allData.total_amount !== 'string')
        this.allData.total_amount =
          this.allData.total_amount.toLocaleString('es-US')
      if (typeof this.allData.report_start_date_remain !== 'string')
        this.allData.report_start_date_remain =
          this.allData.report_start_date_remain.toLocaleString('es-US')
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  computed: {
    mainAssetDocDetails() {
      const data = this.$store.state.requests.mainAssetDocDetails
      return data
    },
    notTakenDocs() {
      const data = this.$store.state.requests.mainAssets
      return data.results
    },
    // isLoading() {
    //   return this.$store.state.requests.isLoading
    // },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      console.log('data', data)
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.warehouse_responsible !== null) {
            if (element.responsible_description !== null) {
              element.sklad_name =
                element.name +
                ' | ' +
                element.responsible_description.staffpersonal_description
                  .first_name +
                ' ' +
                element.responsible_description.staffpersonal_description
                  .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        console.log(data)
        return data
      } else return data
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    ...mapGetters(['allCategoryGroupNames', 'getCategoryGroupData']),
    getDetails() {
      const data =
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set
      const data2 = []
      for (
        let i = 0;
        i <
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set.length;
        i++
      ) {
        data2.push(...data[i].details)
      }
      return data2
    },
    getDoc() {
      const data =
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set
      if (data !== undefined) {
        data.forEach((x) => {
          x.details.forEach((xx) => {
            if (xx.amount !== undefined && xx.amount !== '') {
              xx.parentId = x.id
              if (typeof xx.amount !== 'number') {
                xx.amount = parseInt(xx.amount.replace(/[ ,.]/g, ''), 10)
              }
            }
          })
        })
      }
      return data
    },
    getDetailsMain() {
      const data = this.$store.state.requests.mainassetsDetails
      return data
    },
    conditions() {
      const data = this.$store.state.requests.productCondition
      return data
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: ' Asosiy vositalar malumotlari' }
    ])
  },
  methods: {
    addDocElemet(val, val2) {
      if (val !== null && val2 !== undefined) {
        if (val.id !== undefined && val.id !== '' && val2 !== '') {
          const data = {
            document: val2
          }
          // done
          this.$store.dispatch('mainAssetsMainDocDetails', {
            data: data,
            id: val.id,
            parentId: this.$route.params.id,
            pageIndex: this.$route.params.id
          })
          this.dialog3 = false
          this.mainAssetDocElement = ''
        } else {
          Swal.fire({
            title: 'Xato malumot toldirilgan',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      } else {
        Swal.fire({
          title: 'Xato malumot toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    hideModal1() {
      this.$refs['my-modal1'].toggle()
    },
    showModal1(val) {
      this.deletedDocId = val.id
      this.$refs['my-modal1'].toggle()
    },
    showModal(val) {
      this.deletedDetailsId = val
      this.$refs['my-modal'].show()
    },
    deleteDetail(val) {
      const data = {
        document: null
      }
      // done
      this.$store.dispatch('mainAssetsMainDocDetails', {
        data: data,
        id: val,
        parentId: this.$route.params.id,
        pageIndex: this.$route.params.id
      })
      this.hideModal()
    },
    addDoc(val) {
      const data = {
        main_assets: this.$route.params.id
      }
      // done
      this.$store.dispatch('removeMainAssetsDocElement', {
        data: data,
        id: val,
        parentId: this.$route.params.id,
        pageIndex: this.$route.params.id
      })
      this.dialog2 = false
      this.notTakenDoc = ''
    },
    deleteItem(val) {
      const data = {
        main_assets: null
      }
      this.$store.dispatch('removeMainAssetsDocElement', {
        data: data,
        id: val,
        parentId: this.$route.params.id,
        pageIndex: this.$route.params.id
      })
      this.hideModal1()
    },
    reset() {
      this.$store.dispatch('getmainassetsDetails', this.$route.params.id)
    },
    updateDetail(val) {
      if (val.import_group.code !== val.product_condition.code) {
        delete val.product_condition
      } else {
        val.product_condition = val.product_condition.id
      }
      val.import_group = val.import_group.id
      val.import_category = val.import_category.id
      for (let i in val) {
        val[i] = this.parseToInt(val[i])
      }
      // done
      this.$store.dispatch('mainAssetsMainDocDetails', {
        id: val.id,
        data: val,
        parentId: this.$route.params.id,
        pageIndex: this.$route.params.id
      })
    },
    changeSaveStatus(val) {
      this.dialog = val
    },
    saveDoc() {
      this.saveStatus = false
    },
    getCategorData(val) {
      val.import_category = {}
      this.$store.dispatch('getCategoryGroupData', val.import_group.id)
    },
    editItem(payload) {
      this.saveStatus = true
      this.dialog = true
      this.currentItem = payload
    },
    save(objKey, value) {
      if (objKey == 'amount') {
        value.total = this.parseToInt(value.price) * value.amount
        value.nds_per_item = (this.parseToInt(value.price) * value.nds) / 100
        value.nds_summa = (this.parseToInt(value.total) * value.nds) / 100
        value.total_withnds_summa =
          this.parseToInt(value.total) + this.parseToInt(value.nds_summa)
      }
      if (objKey == 'price') {
        value.total = this.parseToInt(value.price) * value.amount
        value.nds_per_item = (this.parseToInt(value.price) * value.nds) / 100
        value.nds_summa = (this.parseToInt(value.total) * value.nds) / 100
        value.total_withnds_summa =
          this.parseToInt(value.total) + this.parseToInt(value.nds_summa)
      }
      if (objKey == 'total') {
        value.nds_summa = (this.parseToInt(value.total) * value.nds) / 100
        value.total_withnds_summa =
          this.parseToInt(value.total) + this.parseToInt(value.nds_summa)
      }
      if (objKey == 'nds') {
        value.nds_summa = (this.parseToInt(value.total) * value.nds) / 100
        value.total_withnds_summa =
          this.parseToInt(value.total) + this.parseToInt(value.nds_summa)
      }
      if (objKey == 'nds_summa') {
        value.total_withnds_summa =
          this.parseToInt(value.total) + this.parseToInt(value.nds_summa)
      }
      this.snack = true
      this.snackColor = 'success'
      this.snackText = "Ma'lumotlar o'zgartirildi! Iltimos buni tasdiqlang"
    },
    parseToInt(val) {
      if (typeof val !== 'number') {
        return parseInt(val.replace(/[ ,]/g, ''), 10)
      } else return val
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Bekor qilindi'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = "{{ $t('ACTION.UPDATE') }}ga tayyor"
    },
    close() {},
    update(objKey, value) {
      if (value !== undefined && value !== '') {
        if (typeof value !== 'number' && objKey !== 'name')
          value = value.replace(/[ ,]/g, '')
        const data = {
          [objKey]: value
        }
        if (data.total_amount !== undefined) {
          data.total_amount = parseInt(
            data.total_amount.replace(/[ ,.]/g, ''),
            10
          )
        }
        if (data.period_usage !== undefined) {
          data.period_usage = parseInt(
            data.period_usage.replace(/[ ,.]/g, ''),
            10
          )
        }
        this.$store.dispatch('mainAssetsMainPart', {
          id: this.$route.params.id,
          data: data,
          pageIndex: this.$route.params.id
        })
      }
    }
  }
}
</script>

<style scoped>
/* .col-3 {
  height: 50px;
  padding: 10px;
} */
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
